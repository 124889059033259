var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', [_c('v-card-title', {
    staticClass: "text-h5 justify-space-between"
  }, [_c('span', [_vm._v(" Invite Candidates ")]), _c('v-btn', {
    attrs: {
      "icon": ""
    },
    on: {
      "click": _vm.closeDialog
    }
  }, [_c('v-icon', [_vm._v(" " + _vm._s(_vm.mdiClose) + " ")])], 1)], 1), _c('v-card-text', [_vm.errorMessage ? _c('v-alert', {
    attrs: {
      "type": "error",
      "dense": "",
      "text": ""
    }
  }, [_vm._v(_vm._s(_vm.errorMessage))]) : _vm._e(), _c('v-form', {
    ref: "inviteRef",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.validateInvite.apply(null, arguments);
      }
    },
    model: {
      value: _vm.inviteForm,
      callback: function ($$v) {
        _vm.inviteForm = $$v;
      },
      expression: "inviteForm"
    }
  }, [_c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', [_c('label', {
    staticClass: "text-h6 font-weight-bold",
    attrs: {
      "for": "emails"
    }
  }, [_vm._v(" Candidate Emails (comma separated) ")]), _c('v-textarea', {
    attrs: {
      "id": "emails",
      "outlined": "",
      "placeholder": "Enter emails separated by commas"
    },
    on: {
      "keyup": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.splitEmails.apply(null, arguments);
      }
    },
    model: {
      value: _vm.emails,
      callback: function ($$v) {
        _vm.emails = $$v;
      },
      expression: "emails"
    }
  })], 1)], 1), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', [_c('v-combobox', {
    attrs: {
      "items": _vm.duplicateOptions,
      "item-text": "text",
      "item-value": "value",
      "label": "Handle Duplicate Candidates",
      "outlined": ""
    },
    model: {
      value: _vm.selectedOption,
      callback: function ($$v) {
        _vm.selectedOption = $$v;
      },
      expression: "selectedOption"
    }
  })], 1)], 1), _vm._l(_vm.candidates, function (candidate, index) {
    return _c('v-row', {
      key: index,
      attrs: {
        "align": "center",
        "justify": "center"
      }
    }, [_c('v-col', [_c('label', {
      staticClass: "text-h6 font-weight-bold",
      attrs: {
        "for": "name"
      }
    }, [_vm._v(" Name for " + _vm._s(candidate.email) + " ")]), _c('v-text-field', {
      attrs: {
        "id": 'name-' + index,
        "outlined": "",
        "placeholder": "Full name"
      },
      model: {
        value: candidate.name,
        callback: function ($$v) {
          _vm.$set(candidate, "name", $$v);
        },
        expression: "candidate.name"
      }
    })], 1)], 1);
  }), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_c('v-col', {
    staticClass: "d-flex flex-row-reverse"
  }, [_c('v-btn', {
    staticClass: "px-5 py-3 text-none text-body-1",
    attrs: {
      "x-large": "",
      "color": "success",
      "disabled": !_vm.candidates.length,
      "loading": _vm.submitted
    },
    on: {
      "click": _vm.validateInvite
    }
  }, [_vm._v(" Send Invites ")])], 1)], 1)], 2)], 1), _c('v-row', [_c('v-col', {
    staticClass: "text-center text-h6"
  }, [_vm._v(" - OR - ")])], 1), _c('v-card-actions', [_c('v-row', {
    attrs: {
      "justify": "center",
      "align": "center"
    }
  }, [_c('div', {
    staticClass: "text-center pb-5"
  }, [_c('v-btn', {
    staticClass: "pa-5 text-h4 primary--text text-none",
    attrs: {
      "elevation": "0",
      "color": "white"
    },
    on: {
      "click": _vm.showBulkInvite
    }
  }, [_c('v-icon', {
    attrs: {
      "large": "",
      "left": ""
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiStarCircle) + " ")]), _vm._v(" Invite Candidates in Bulk ")], 1)], 1)]), _vm.bulkInvite ? _c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "md": "8"
    }
  }, [_c('v-file-input', {
    attrs: {
      "accept": ".csv",
      "label": "Attach CSV File",
      "hint": "Columns format: name,email",
      "persistent-hint": "",
      "outlined": "",
      "prepend-icon": "",
      "prepend-inner-icon": _vm.mdiAttachment
    },
    model: {
      value: _vm.candidatesCsv,
      callback: function ($$v) {
        _vm.candidatesCsv = $$v;
      },
      expression: "candidatesCsv"
    }
  })], 1), _c('v-col', {
    staticClass: "d-flex flex-row-reverse"
  }, [_c('v-btn', {
    staticClass: "px-5 py-6 text-none text-body-1",
    attrs: {
      "x-large": "",
      "color": "success",
      "loading": _vm.invitesLoading,
      "height": "35",
      "disabled": !_vm.candidatesCsv
    },
    on: {
      "click": _vm.bulkInviteCandidates
    }
  }, [_vm._v(" Send Invites ")])], 1)], 1) : _vm._e(), _c('v-row', {
    attrs: {
      "align": "center",
      "justify": "center"
    }
  }, [_vm.csvErrorText.length > 0 ? _c('v-btn', {
    staticClass: "px-5 py-6 text-none text-body-1 font-weight-medium",
    attrs: {
      "x-large": "",
      "color": "success",
      "download": "candidates_error.csv",
      "href": `data:application/octet-stream,${encodeURI(this.csvErrorText)}`
    }
  }, [_vm._v(" Download CSV with errors ")]) : _vm._e()], 1), _c('go-premium', {
    ref: "unlock",
    attrs: {
      "imageUrl": "images/GoPremium.png"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }