<template>
  <v-card>
    <v-card-title class="text-h5 justify-space-between">
      <span> Invite Candidates </span>
      <v-btn icon @click="closeDialog">
        <v-icon>
          {{ mdiClose }}
        </v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-alert v-if="errorMessage" type="error" dense text>{{
        errorMessage
      }}</v-alert>
      <v-form
        ref="inviteRef"
        v-model="inviteForm"
        @submit.prevent="validateInvite"
      >
        <v-row align="center" justify="center">
          <v-col>
            <label class="text-h6 font-weight-bold" for="emails">
              Candidate Emails (comma separated)
            </label>
            <v-textarea
              id="emails"
              outlined
              v-model="emails"
              placeholder="Enter emails separated by commas"
              @keyup.enter.prevent="splitEmails"
            />
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col>
            <v-combobox
              v-model="selectedOption"
              :items="duplicateOptions"
              item-text="text"
              item-value="value"
              label="Handle Duplicate Candidates"
              outlined
            ></v-combobox>
          </v-col>
        </v-row>
        <v-row
          v-for="(candidate, index) in candidates"
          :key="index"
          align="center"
          justify="center"
        >
          <v-col>
            <label class="text-h6 font-weight-bold" for="name">
              Name for {{ candidate.email }}
            </label>
            <v-text-field
              :id="'name-' + index"
              outlined
              v-model="candidate.name"
              placeholder="Full name"
            />
          </v-col>
        </v-row>
        <v-row align="center" justify="center">
          <v-col class="d-flex flex-row-reverse">
            <v-btn
              x-large
              color="success"
              @click="validateInvite"
              :disabled="!candidates.length"
              class="px-5 py-3 text-none text-body-1"
              :loading="submitted"
            >
              Send Invites
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-card-text>
    <v-row>
      <v-col class="text-center text-h6"> - OR - </v-col>
    </v-row>
    <v-card-actions>
      <v-row justify="center" align="center">
        <div class="text-center pb-5">
          <v-btn
            @click="showBulkInvite"
            class="pa-5 text-h4 primary--text text-none"
            elevation="0"
            color="white"
          >
            <v-icon large left> {{ mdiStarCircle }} </v-icon>
            Invite Candidates in Bulk
          </v-btn>
        </div>
      </v-row>
      <v-row v-if="bulkInvite">
        <v-col cols="12" md="8">
          <v-file-input
            accept=".csv"
            label="Attach CSV File"
            hint="Columns format: name,email"
            persistent-hint
            outlined
            prepend-icon=""
            :prepend-inner-icon="mdiAttachment"
            v-model="candidatesCsv"
          ></v-file-input>
        </v-col>
        <v-col class="d-flex flex-row-reverse">
          <v-btn
            x-large
            color="success"
            class="px-5 py-6 text-none text-body-1"
            :loading="invitesLoading"
            height="35"
            @click="bulkInviteCandidates"
            :disabled="!candidatesCsv"
          >
            Send Invites
          </v-btn>
        </v-col>
      </v-row>
      <v-row align="center" justify="center">
        <v-btn
          v-if="csvErrorText.length > 0"
          x-large
          color="success"
          class="px-5 py-6 text-none text-body-1 font-weight-medium"
          download="candidates_error.csv"
          :href="`data:application/octet-stream,${encodeURI(
            this.csvErrorText,
          )}`"
        >
          Download CSV with errors
        </v-btn>
      </v-row>
      <go-premium ref="unlock" imageUrl="images/GoPremium.png"> </go-premium>
    </v-card-actions>
  </v-card>
</template>
<script>
import { mapActions, mapGetters, mapState } from 'vuex'
import { mdiStarCircle, mdiAttachment, mdiClose, mdiDownload } from '@mdi/js'
import GoPremium from '../assessment/GoPremium.vue'
import { validateEmail } from '@/utils/helper.js'

export default {
  components: { GoPremium },
  data() {
    return {
      mdiStarCircle,
      mdiAttachment,
      mdiClose,
      candName: '',
      email: '',
      submitted: false,
      inviteForm: null,
      inviteDailog: false,
      candidatesCsv: null,
      invitesLoading: false,
      bulkInvite: false,
      csvErrorText: '',
      inviteForm: true,
      submitted: false,
      emails: '',
      candidates: [],
      errorMessage: '',
      duplicateOptions: [
        { text: 'Skip Duplicate Candidates', value: 1 },
        { text: 'Reinvite All Duplicate Candidates', value: 2 },
        { text: 'Reinvite Only Pending Duplicate Candidates', value: 3 },
      ],
      selectedOption: { text: 'Skip Duplicate Candidates', value: 1 },
    }
  },
  computed: {
    ...mapState('recruiter', ['assessment']),
    ...mapGetters('customer', ['hiringPlanKeys']),
    ...mapState('customer', ['customer']),
    hiringPlan() {
      return this.hiringPlanKeys[this.customer?.getHiringPlan()]
    },
    hasPremium() {
      return this.hiringPlan === 'SCALE'
    },
  },
  methods: {
    ...mapActions('recruiter', ['assessmentInvite', 'assessmentInviteBatch']),
    validateEmail: validateEmail,
    splitEmails() {
      // Simple regex for basic email validation

      this.candidates = this.emails.split(/[\s,]+/).map((email) => {
        const trimmedEmail = email.trim()
        var isValidCheck = validateEmail(trimmedEmail)
        if (!isValidCheck) {
          // this.$store.dispatch('notifs/addNotif', {
          //   text: 'Invalid Email: ' + trimmedEmail,
          //   type: 'error',
          // })
          this.errorMessage = 'Invalid Email: ' + trimmedEmail
        } else {
          this.errorMessage = ''
        }
        return {
          email: trimmedEmail,
          name: '',
          isValid: isValidCheck,
        }
      })
    },

    validateInvite() {
      const invalidCandidates = []
      const validCandidates = []

      this.candidates.forEach((candidate) => {
        if (validateEmail(candidate.email)) {
          validCandidates.push(candidate)
        } else {
          invalidCandidates.push(candidate)
        }
      })

      if (invalidCandidates.length > 0) {
        // Handle invalid candidates, e.g., show an error message
        console.error(
          'Invalid candidates:',
          invalidCandidates.map((c) => c.email).join(', '),
        )
        this.$store.dispatch('notifs/addNotif', {
          text:
            'Invalid Email: ' +
            invalidCandidates.map((c) => c.email).join(', '),
          type: 'error',
        })
        // Optionally, stop the process or handle it differently
      } else {
        // All candidates have valid emails, proceed with the invitation process
        this.sendInvites(validCandidates)
      }
    },
    sendInvites(validCandidates) {
      console.log('Sending invite ...', validCandidates)
      this.submitted = true
      this.assessmentInvite({
        id: this.assessment?.getId(),
        candidates: validCandidates,
        duplicatehandling: this.selectedOption.value,
      })
        .then((res) => {
          this.candidates = []
          this.submitted = false
          this.$store.dispatch('notifs/addNotif', {
            text: 'Candidate Invited Successfully!',
            type: 'success',
          })
          this.$emit('close')
        })
        .catch((e) => {
          this.submitted = false
          this.$store.dispatch('notifs/addNotif', {
            text: e?.message || 'Error Inviting Candidate, please retry!',
            type: 'error',
          })
          // console.error(e);
        })
    },
    async showBulkInvite() {
      if (!this.hasPremium) {
        await this.$refs.unlock.open(
          'To UNLOCK this feature, become a PREMIUM member today!',
        )
      } else {
        this.bulkInvite = true
      }
    },
    async bulkInviteCandidates() {
      console.log('Saving csv ..')
      this.invitesLoading = true
      await this.candidatesCsv.text().then((content) => {
        this.assessmentInviteBatch({
          candidatesCsv: content,
          id: this.assessment?.getId(),
        })
          .then((res) => {
            this.clearCandidatesCsv()
            this.invitesLoading = false
            if (res?.toObject().error) {
              // show csv somehow, since there are errors
              this.csvErrorText = res?.getResults()
              this.$store.dispatch('notifs/addNotif', {
                text: 'Some errors found in CSV',
                type: 'error',
              })
            } else {
              this.$store.dispatch('notifs/addNotif', {
                text: 'Candidates Invited Successfully!',
                type: 'success',
              })
              this.$nextTick(() => {
                this.$emit('close')
              })
            }
          })
          .catch((e) => {
            this.invitesLoading = false
            this.$store.dispatch('notifs/addNotif', {
              text:
                e?.message || 'Error Bulk Inviting Candidates, please retry!',
              type: 'error',
            })
            // console.error(e);
          })
      })
    },

    clearCandidatesCsv() {
      this.candidatesCsv = null
    },

    closeDialog() {
      this.$emit('closeDialog')
    },
  },
  watch: {
    emails() {
      this.splitEmails()
    },
  },
}
</script>
