var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "px-5"
  }, [_vm.tableType !== 'JOB' ? _c('v-col', [_c('span', {
    staticClass: "font-weight-bold text-h6 black--text"
  }, [_vm._v(" Candidates: ")])]) : _vm._e(), _c('v-col', {
    staticClass: "d-flex justify-end"
  }, [_c('SmallConfirmation', {
    ref: "delconfirm"
  }), _vm.tableType !== 'JOB' ? _c('div', [_c('v-dialog', {
    attrs: {
      "width": "70%"
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          staticClass: "text-none text-body-1",
          attrs: {
            "color": "primary",
            "text": "",
            "id": "assessment-invite"
          }
        }, 'v-btn', attrs, false), on), [_c('v-icon', {
          attrs: {
            "left": "",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiCloudUpload) + " ")]), _vm._v(" Invite Candidates ")], 1)];
      }
    }], null, false, 1067842834),
    model: {
      value: _vm.dialog,
      callback: function ($$v) {
        _vm.dialog = $$v;
      },
      expression: "dialog"
    }
  }, [_c('v-card-text', {
    staticClass: "pa-0",
    staticStyle: {
      "overflow-x": "hidden",
      "overflow-y": "hidden"
    }
  }, [_c('Invite', {
    on: {
      "closeDialog": _vm.returnToAllAssessment
    }
  })], 1)], 1)], 1) : _vm._e(), _c('div', [_c('v-btn', {
    staticClass: "text-none text-body-1",
    attrs: {
      "color": "primary",
      "text": "",
      "loading": _vm.downloadsLoading,
      "height": "35"
    },
    on: {
      "click": _vm.bulkDownloadCandidates
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiCloudDownload) + " ")]), _vm._v(" Download Candidates Csv ")], 1)], 1), _c('div', [_c('v-btn', {
    staticClass: "text-none text-body-1",
    attrs: {
      "color": "primary",
      "text": "",
      "loading": _vm.reportLoading,
      "height": "35"
    },
    on: {
      "click": _vm.fullCandidateReport
    }
  }, [_c('v-icon', {
    attrs: {
      "left": "",
      "color": "primary"
    }
  }, [_vm._v(" " + _vm._s(_vm.mdiCloudDownload) + " ")]), _vm._v(" Download Full Candidate Report PDF ")], 1)], 1)], 1)], 1), _c('v-row', {
    staticClass: "d-flex justify-space-between px-5"
  }, [_c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "outlined": "",
      "prepend-inner-icon": _vm.mdiMagnify,
      "dense": "",
      "label": "Search Candidates",
      "single-line": "",
      "hide-details": ""
    },
    model: {
      value: _vm.searchCandidate,
      callback: function ($$v) {
        _vm.searchCandidate = $$v;
      },
      expression: "searchCandidate"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "8"
    }
  }, [_c('v-row', {
    staticClass: "justify-end"
  }, [_vm.tableType !== 'JOB' ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.ratingFilters,
      "label": "Ratings",
      "outlined": "",
      "dense": "",
      "chips": "",
      "clearable": "",
      "menu-props": {
        maxHeight: '400',
        maxWidth: '300',
        bottom: true,
        offsetY: true
      },
      "multiple": "",
      "placeholder": "Ratings"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref2) {
        var item = _ref2.item,
          index = _ref2.index;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return _vm.removeSelection('RATING', item);
            }
          }
        }, [_c('span', [_vm._v(_vm._s(item.text))])])];
      }
    }], null, false, 3363203898),
    model: {
      value: _vm.ratingsSelected,
      callback: function ($$v) {
        _vm.ratingsSelected = $$v;
      },
      expression: "ratingsSelected"
    }
  })], 1) : _vm._e(), _vm.tableType !== 'JOB' ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.statusFilters,
      "label": "Status",
      "outlined": "",
      "dense": "",
      "chips": "",
      "clearable": "",
      "menu-props": {
        maxHeight: '400',
        maxWidth: '300',
        bottom: true,
        offsetY: true
      },
      "multiple": "",
      "placeholder": "Status"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref3) {
        var item = _ref3.item,
          index = _ref3.index;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return _vm.removeSelection('STATUS', item);
            }
          }
        }, [_c('span', [_vm._v(_vm._s(item.text))])])];
      }
    }], null, false, 3027785961),
    model: {
      value: _vm.statusSelected,
      callback: function ($$v) {
        _vm.statusSelected = $$v;
      },
      expression: "statusSelected"
    }
  })], 1) : _vm._e(), _vm.tableType !== 'JOB' ? _c('v-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.stageFilters,
      "label": "Stage",
      "outlined": "",
      "dense": "",
      "chips": "",
      "clearable": "",
      "menu-props": {
        maxHeight: '400',
        maxWidth: '300',
        bottom: true,
        offsetY: true
      },
      "multiple": "",
      "placeholder": "Stage"
    },
    scopedSlots: _vm._u([{
      key: "selection",
      fn: function (_ref4) {
        var item = _ref4.item,
          index = _ref4.index;
        return [_c('v-chip', {
          attrs: {
            "small": "",
            "close": ""
          },
          on: {
            "click:close": function ($event) {
              return _vm.removeSelection('STAGE', item);
            }
          }
        }, [_c('span', [_vm._v(_vm._s(item.text))])])];
      }
    }], null, false, 1811583193),
    model: {
      value: _vm.stageSelected,
      callback: function ($$v) {
        _vm.stageSelected = $$v;
      },
      expression: "stageSelected"
    }
  })], 1) : _vm._e()], 1)], 1)], 1), _c('v-data-table', {
    attrs: {
      "single-select": false,
      "show-select": "",
      "headers": _vm.headers,
      "items": _vm.filteredCandidates,
      "loading": _vm.candidateTableLoading,
      "search": _vm.searchCandidate,
      "items-per-page": 15
    },
    on: {
      "click:row": _vm.handleCandidate
    },
    scopedSlots: _vm._u([{
      key: "item.status",
      fn: function (_ref5) {
        var item = _ref5.item;
        return [item.email == 'hello+sample1@codedrills.io' ? _c('v-chip', {
          attrs: {
            "color": item.statusColor,
            "id": "candidate-sample"
          }
        }, [_vm._v(" " + _vm._s(item.status) + " ")]) : _c('v-chip', {
          attrs: {
            "color": item.statusColor
          }
        }, [_vm._v(" " + _vm._s(item.status) + " ")])];
      }
    }, {
      key: "item.percent",
      fn: function (_ref6) {
        var item = _ref6.item;
        return [_vm._v(" " + _vm._s(_vm.percentagePretty(item)) + " ")];
      }
    }, {
      key: "item.rawScore",
      fn: function (_ref7) {
        var item = _ref7.item;
        return [_vm._v(" " + _vm._s(_vm.prettyScore(item)) + " ")];
      }
    }, {
      key: "item.stage",
      fn: function (_ref8) {
        var item = _ref8.item;
        return [_c('v-chip', [_vm._v(" " + _vm._s(_vm.testStagesPretty[_vm.testStages[item.stage]]) + " ")])];
      }
    }, {
      key: "item.rating",
      fn: function (_ref9) {
        var item = _ref9.item;
        return [_c('v-rating', {
          attrs: {
            "value": item.rating,
            "color": "primary",
            "length": "5"
          }
        })];
      }
    }, {
      key: "item.inviteDate",
      fn: function (_ref10) {
        var item = _ref10.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.inviteDate)) + " ")];
      }
    }, {
      key: "item.endTime",
      fn: function (_ref11) {
        var item = _ref11.item;
        return [_vm._v(" " + _vm._s(_vm.formatDate(item.endTime)) + " ")];
      }
    }, {
      key: "item.resumeLink",
      fn: function (_ref12) {
        var item = _ref12.item;
        return [_c('v-btn', {
          attrs: {
            "icon": "",
            "href": _vm.openLink(item),
            "target": "_blnak"
          }
        }, [_c('v-icon', {
          staticClass: "text-none text-body-1",
          attrs: {
            "color": "primary"
          }
        }, [_vm._v(" Open Resume ")])], 1), _c('SmallConfirmation', {
          ref: "delconfirm"
        })];
      }
    }, _vm.tableType !== 'JOB' ? {
      key: "no-data",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-center"
        }, [_c('span', {
          staticClass: "d-flex flex-column py-5"
        }, [_c('v-img', {
          attrs: {
            "src": require("@/assets/images/campaign/best.png"),
            "width": "350",
            "max-height": "380"
          }
        }), _c('v-btn', {
          staticClass: "text-none text-h5",
          attrs: {
            "color": "primary",
            "x-large": "",
            "text": ""
          },
          on: {
            "click": function ($event) {
              _vm.dialog = true;
            }
          }
        }, [_c('v-icon', {
          attrs: {
            "large": "",
            "left": "",
            "color": "primary"
          }
        }, [_vm._v(" " + _vm._s(_vm.mdiCloudUpload) + " ")]), _vm._v(" Invite Candidates ")], 1)], 1)])];
      },
      proxy: true
    } : null], null, true),
    model: {
      value: _vm.candidateSelection,
      callback: function ($$v) {
        _vm.candidateSelection = $$v;
      },
      expression: "candidateSelection"
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }