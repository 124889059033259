<template>
  <div>
    <v-row class="px-5">
      <v-col v-if="tableType !== 'JOB'">
        <span class="font-weight-bold text-h6 black--text"> Candidates: </span>
      </v-col>
      <v-col class="d-flex justify-end">
        <SmallConfirmation ref="delconfirm" />

        <div v-if="tableType !== 'JOB'">
          <v-dialog v-model="dialog" width="70%">
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                color="primary"
                text
                class="text-none text-body-1"
                id="assessment-invite"
              >
                <v-icon left color="primary">
                  {{ mdiCloudUpload }}
                </v-icon>
                Invite Candidates
              </v-btn>
            </template>

            <v-card-text
              class="pa-0"
              style="overflow-x: hidden; overflow-y: hidden"
            >
              <Invite @closeDialog="returnToAllAssessment" />
            </v-card-text>
          </v-dialog>
        </div>
        <div>
          <v-btn
            color="primary"
            text
            class="text-none text-body-1"
            :loading="downloadsLoading"
            height="35"
            @click="bulkDownloadCandidates"
          >
            <v-icon left color="primary">
              {{ mdiCloudDownload }}
            </v-icon>
            Download Candidates Csv
          </v-btn>
        </div>
        <div>
          <v-btn
            color="primary"
            text
            class="text-none text-body-1"
            :loading="reportLoading"
            height="35"
            @click="fullCandidateReport"
          >
            <v-icon left color="primary">
              {{ mdiCloudDownload }}
            </v-icon>
            Download Full Candidate Report PDF
          </v-btn>
        </div>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-space-between px-5">
      <v-col cols="4">
        <v-text-field
          outlined
          v-model="searchCandidate"
          :prepend-inner-icon="mdiMagnify"
          dense
          label="Search Candidates"
          single-line
          hide-details
        ></v-text-field>
      </v-col>
      <v-col cols="8">
        <v-row class="justify-end">
          <v-col cols="4" v-if="tableType !== 'JOB'">
            <v-select
              :items="ratingFilters"
              label="Ratings"
              v-model="ratingsSelected"
              outlined
              dense
              chips
              clearable
              :menu-props="{
                maxHeight: '400',
                maxWidth: '300',
                bottom: true,
                offsetY: true,
              }"
              multiple
              placeholder="Ratings"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  small
                  close
                  @click:close="removeSelection('RATING', item)"
                >
                  <span>{{ item.text }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="4" v-if="tableType !== 'JOB'">
            <v-select
              :items="statusFilters"
              label="Status"
              v-model="statusSelected"
              outlined
              dense
              chips
              clearable
              :menu-props="{
                maxHeight: '400',
                maxWidth: '300',
                bottom: true,
                offsetY: true,
              }"
              multiple
              placeholder="Status"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  small
                  close
                  @click:close="removeSelection('STATUS', item)"
                >
                  <span>{{ item.text }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="4" v-if="tableType !== 'JOB'">
            <v-select
              :items="stageFilters"
              label="Stage"
              v-model="stageSelected"
              outlined
              dense
              chips
              clearable
              :menu-props="{
                maxHeight: '400',
                maxWidth: '300',
                bottom: true,
                offsetY: true,
              }"
              multiple
              placeholder="Stage"
            >
              <template v-slot:selection="{ item, index }">
                <v-chip
                  small
                  close
                  @click:close="removeSelection('STAGE', item)"
                >
                  <span>{{ item.text }}</span>
                </v-chip>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <!-- candidate table -->
    <v-data-table
      v-model="candidateSelection"
      :single-select="false"
      show-select
      :headers="headers"
      :items="filteredCandidates"
      :loading="candidateTableLoading"
      @click:row="handleCandidate"
      :search="searchCandidate"
      :items-per-page="15"
    >
      <template v-slot:item.status="{ item }">
        <v-chip
          :color="item.statusColor"
          v-if="item.email == 'hello+sample1@codedrills.io'"
          id="candidate-sample"
        >
          {{ item.status }}
        </v-chip>
        <v-chip :color="item.statusColor" v-else>
          {{ item.status }}
        </v-chip>
      </template>
      <template v-slot:item.percent="{ item }">
        {{ percentagePretty(item) }}
      </template>
      <template v-slot:item.rawScore="{ item }">
        {{ prettyScore(item) }}
      </template>
      <template v-slot:item.stage="{ item }">
        <!-- <v-select
            dense
            outlined
            :items="testStagesSelector"
            item-text="text"
            item-value="stage"
            v-model="testStages[item.stage]"
            @change="candidateStageUpdate(item)"
            @click.stop
            hide-details
          >
          </v-select> -->
        <v-chip>
          {{ testStagesPretty[testStages[item.stage]] }}
        </v-chip>
      </template>
      <template v-slot:item.rating="{ item }">
        <v-rating :value="item.rating" color="primary" length="5"> </v-rating>
        <!-- <v-rating
            color="primary"
            hover
            length="5"
            v-model="item.rating"
            @click.stop
            @input="candidateRatingUpdate(item)"
          ></v-rating> -->
      </template>
      <template v-slot:item.inviteDate="{ item }">
        {{ formatDate(item.inviteDate) }}
      </template>
      <template v-slot:item.endTime="{ item }">
        {{ formatDate(item.endTime) }}
      </template>
      <template v-slot:item.resumeLink="{ item }">
        <v-btn icon :href="openLink(item)" target="_blnak">
          <v-icon class="text-none text-body-1" color="primary">
            Open Resume
          </v-icon>
        </v-btn>
        <SmallConfirmation ref="delconfirm" />
      </template>
      <template v-if="tableType !== 'JOB'" v-slot:no-data>
        <div class="d-flex justify-center">
          <span class="d-flex flex-column py-5">
            <v-img
              src="~@/assets/images/campaign/best.png"
              width="350"
              max-height="380"
            ></v-img>
            <v-btn
              color="primary"
              x-large
              text
              class="text-none text-h5"
              @click="dialog = true"
            >
              <v-icon large left color="primary">
                {{ mdiCloudUpload }}
              </v-icon>
              Invite Candidates
            </v-btn>
          </span>
        </div>
      </template>
    </v-data-table>
  </div>
</template>
<script>
import {
  mdiCloudUpload,
  mdiCloudDownload,
  mdiTrashCan,
  mdiMagnify,
} from '@mdi/js'
import { mapGetters, mapState, mapActions } from 'vuex'
import Invite from '@/components/recruiter/Invite.vue'
import SmallConfirmation from '@/components/assessment/SmallConfirmation.vue'
import moment, { duration } from 'moment'
import { saveStringAsFile } from '@/utils/helper'

export default {
  data() {
    return {
      dialog: false,
      infoPanel: 0,
      candidateSelection: [],
      mdiTrashCan,
      mdiCloudUpload,
      mdiCloudDownload,
      mdiMagnify,
      downloadsLoading: false,
      reportLoading: false,
      candidatesCsv: null,
      bulkDownload: false,
      candidateCsvText: '',
      selectedCandidates: [],
      candidateHeaders: [
        {
          text: 'Candidate',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Test Status',
          value: 'status',
        },
        {
          text: 'Percentage',
          value: 'percent',
          align: 'center',
        },
        {
          text: 'Score',
          value: 'rawScore',
          align: 'center',
        },
        {
          text: 'Stage',
          value: 'stage',
        },
        {
          text: 'Rating',
          value: 'rating',
          align: 'center',
          sort: (a, b) => b - a,
        },
        {
          text: 'Invited At',
          value: 'inviteDate',
          align: 'center',
        },
        {
          text: 'Completed At',
          value: 'endTime',
          align: 'center',
        },
      ],
      jobHeaders: [
        {
          text: 'Candidate',
          value: 'name',
        },
        {
          text: 'Email',
          value: 'email',
        },
        {
          text: 'Applied On',
          value: 'applied',
        },
        {
          text: 'Resume',
          value: 'resumeLink',
        },
      ],
      searchCandidate: '',
      ratingsSelected: [],
      ratingFilters: [
        {
          text: '1',
          value: 1,
        },
        {
          text: '2',
          value: 2,
        },
        {
          text: '3',
          value: 3,
        },
        {
          text: '4',
          value: 4,
        },
        {
          text: '5',
          value: 5,
        },
      ],
      statusSelected: [],
      statusFilters: [
        {
          text: 'Invited',
          value: 1,
        },
        {
          text: 'In Progress',
          value: 2,
        },
        {
          text: 'Completed',
          value: 3,
        },
      ],
      stageSelected: [],
      stageFilters: [
        {
          text: 'Screening',
          value: 1,
        },
        {
          text: 'Interview',
          value: 2,
        },
        {
          text: 'Shortlisted',
          value: 3,
        },
        {
          text: 'Selected',
          value: 4,
        },
        {
          text: 'Rejected',
          value: 5,
        },
      ],
    }
  },
  props: {
    displayCandidates: {
      type: Array,
      require: true,
    },
    candidateTableLoading: {
      type: Boolean,
      require: true,
    },
    tableType: {
      type: String,
      require: true,
    },
    url: {
      type: String,
      require: true,
    },
  },
  computed: {
    ...mapGetters('recruiter', [
      'testStages',
      'testStagesSelector',
      'testStagesPretty',
    ]),
    ...mapGetters('customer', ['hiringPlanKeys']),
    ...mapState('customer', ['customer']),
    ...mapState('jobs', ['allJobCandidates']),
    hiringPlan() {
      return this.hiringPlanKeys[this.customer?.getHiringPlan()]
    },
    hasPremium() {
      return this.hiringPlan === 'SCALE'
    },
    headers() {
      console.log('JT ...', this.tableType)
      if (this.tableType === 'JOB') return this.jobHeaders
      else return this.candidateHeaders
    },
    filteredCandidates() {
      let filtered = this.displayCandidates
      // if no filter, display all
      filtered = this.displayCandidates
        .filter((c) => {
          // rating filter
          if (this.ratingsSelected.length >= 1) {
            return this.ratingsSelected.includes(c.rating)
          } else return true
        })
        .filter((c) => {
          // status filter
          if (this.statusSelected.length >= 1) {
            return this.statusSelected.includes(c.statusInt)
          } else return true
        })
        .filter((c) => {
          // stage filter
          if (this.stageSelected.length >= 1) {
            return this.stageSelected.includes(c.stage)
          } else return true
        })
      console.log('Filtered candidates ...', filtered)
      return filtered
    },
  },
  methods: {
    ...mapActions('recruiter', [
      'downloadCandidatesReport',
      'exportFullCandidateReport',
    ]),
    fullCandidateReport() {
      this.reportLoading = true
      var candidateIds = this.candidateSelection.map((c) => c.id)
      if (candidateIds.length === 0) {
        candidateIds = this.displayCandidates.map((c) => c.id)
      }
      this.exportFullCandidateReport({
        url: this.url,
        candidateIds: candidateIds,
      })
        .then((response) => {
          // Directly use the response if it's already a Blob
          // If using axios, the response will be in `response.data`
          console.log('Response', response.getReportPdf())
          const blob = new Blob([response.getReportPdf()], {
            type: 'application/pdf',
          })

          const url = window.URL.createObjectURL(blob)

          const link = document.createElement('a')
          link.href = url

          link.setAttribute('download', 'report.pdf')

          document.body.appendChild(link)
          link.click()
          document.body.removeChild(link)

          window.URL.revokeObjectURL(url)

          console.log('Report downloaded')
        })
        .catch((e) => {
          this.$store.dispatch('notifs/addNotif', {
            text: e?.message || 'Error downloading report, please retry!',
            type: 'error',
          })
          console.log('Error downloading report', e)
        })
        .finally(() => {
          this.reportLoading = false
        })
    },
    removeSelection(type, item) {
      if (type === 'STATUS') {
        this.statusSelected = [
          ...this.statusSelected.filter((v) => v !== item.value),
        ]
      } else if (type === 'RATING') {
        this.ratingsSelected = [
          ...this.ratingsSelected.filter((v) => v !== item.value),
        ]
      } else if (type === 'STAGE') {
        this.stageSelected = [
          ...this.stageSelected.filter((v) => v !== item.value),
        ]
      } else {
      }
    },
    candidateRatingUpdate({ id, email, rating }) {
      // @TODO: Pending, event propagation wasnt stopping properly
      console.log(`candidateRatingUpdate`, { id, email, rating })
    },
    handleCandidate(row) {
      console.log('Emiting handle candidate', row)
      this.$emit('handleCandidate', row)
    },
    returnToAllAssessment() {
      console.log('Emiting return to all assessment')
      this.dialog = false
      this.$emit('returnToAllAssessment')
    },
    openLink(item) {
      console.log('item...', item)
      return item.resumeLink
    },
    bulkDownloadCandidates() {
      if (this.tableType === 'JOB') {
        this.clearCandidatesCsv()
        var header = 'Name, Email, Date applied\n'
        this.candidateCsvText =
          header +
          this.allJobCandidates
            .map((cand) => {
              return (
                cand.getResume().getName() +
                ',' +
                cand.getResume().getEmail() +
                ',' +
                this.formatDate(cand?.getAppliedOn())
              )
            })
            .join('\n')
        saveStringAsFile(this.candidateCsvText)
        console.log('Csv', this.candidateCsvText)
      } else {
        this.clearCandidatesCsv()
        console.log('Bulk download candidates', this.url)
        this.downloadCandidatesReport({ url: this.url }).then((res) => {
          this.candidateCsvText = res.getReportCsv()
          // Set report as uri to save as file
          saveStringAsFile(this.candidateCsvText)
          console.log('Csv .....', this.candidateCsvText)
        })
      }
    },
    formatDate(value) {
      if (value) {
        console.log('Date ...', value)
        return moment.unix(value / 1000).format('DD-MM-YYYY hh:mm A')
      } else return '-'
    },
    clearCandidatesCsv() {
      this.candidatesCsv = null
    },
    percentagePretty(item) {
      if (isNaN(item?.percent) && item?.status == 'Completed') return '0.00%'
      else if (item?.status == 'Completed')
        return `${(item?.percent * 100).toFixed(2)}%`
      else return '-'
    },
    prettyScore(item) {
      if (item?.status == 'Completed' && item?.maxScore !== 0)
        return `${Math.round(item?.rawScore * 100) / 100.0}`
      else return '-'
    },
  },
  components: { Invite, SmallConfirmation },
  watch: {},
}
</script>

<style scoped>
tr {
  cursor: pointer;
}
</style>
